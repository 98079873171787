let BASE_URL;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/api';
} else {
  BASE_URL = 'http://www.xbdeer.com';
}

// const

const TIME_OUT = 15000;

export { BASE_URL, TIME_OUT };
