<template>
  <left-menu />
  <!-- 加载动画 -->
  <loading v-if="$store.state.isLoading"></loading>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import loading from './components/common/loading/loading.vue'

import LeftMenu from '@/views/leftmenu/leftmenu.vue'

// 自适应屏幕
import { onResize } from '@/utils/utils.js'
onResize()

const store = useStore()

//播放点击模块的声音
const audioMusic = ref(null)
const playMusic = () => {
  if (store.state.setUp.soundEffect == 1) {
    audioMusic.value.play()
  }
}

watch(
  () => store.state.isBtnMusic,
  (newValue) => {
    if (newValue) {
      playMusic()
      setTimeout(() => {
        store.state.isBtnMusic = false
      }, 500)
    }
  }
)
</script>
<style lang="scss" scoped>
</style>
