/*
  Class of Observer
*/
class Observer {
    constructor() {
      let EventEmitter = require("events");
      this.observer = new EventEmitter();
    }
  
    destory() {
      this.observer.off();
      this.observer = null;
    }
  
    on(event, listener) {
      if(event !== 'error') {
        this.observer.addListener(event, listener);
      } else {
        this.observer.addListener('internalError', listener);
      }
    }
  
    trigger(event, ...data) {
      if(event !== 'error') {
        this.observer.emit(event, ...data);
      } else {
        this.observer.emit('internalError', ...data);
      }
    }
  
    off(event) {
      if(!event) {
        this.observer.removeAllListeners();
        return;
      }
      this.observer.removeAllListeners(event);
    }
  }
  
  module.exports = Observer;
  