/**
 * 格式化时间戳 Y-m-d H:i:s
 * @param {String} format Y-m-d H:i:s
 * @param {Number} timestamp 时间戳
 * @return {String}
 */
export const date = (format, timeStamp) => {
  if ('' + timeStamp.length <= 10) {
    timeStamp = +timeStamp * 1000
  } else {
    timeStamp = +timeStamp
  }
  let _date = new Date(timeStamp),
    Y = _date.getFullYear(),
    m = _date.getMonth() + 1,
    d = _date.getDate(),
    H = _date.getHours(),
    i = _date.getMinutes(),
    s = _date.getSeconds()

  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  H = H < 10 ? '0' + H : H
  i = i < 10 ? '0' + i : i
  s = s < 10 ? '0' + s : s

  return format.replace(/[YmdHis]/g, (key) => {
    return {
      Y,
      m,
      d,
      H,
      i,
      s
    }[key]
  })
}

/**
 * 调整屏幕宽度
 */
export const onResize = () => {
  //动态调整屏幕大小
  let Wi = document.documentElement.clientWidth
  let percent = Wi / 1920
  document.documentElement.style.fontSize = 18 * percent + 'px'

  onresize = () => {
    let Wi = document.documentElement.clientWidth
    let percent = Wi / 1920
    document.documentElement.style.fontSize = 18 * percent + 'px'
    // return document.documentElement.clientHeight
  }
  window.addEventListener('resize', onresize, true)
}

// 设置/获取/删除/清空本地缓存
class LocalCache {
  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  get(key) {
    const value = window.localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
  }

  del(key) {
    window.localStorage.removeItem(key)
  }

  clear() {
    window.localStorage.clear()
  }
}

export const localCache = new LocalCache()
