class TestC1 {
    constructor(){

    }

    DoAdd(a,b) {
        return a+b;
    }
}

module.exports = TestC1;
