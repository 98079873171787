import xbtool from '@/utils/xbtool/src/index.js'
import uni from '@/utils/eventbus.js'
import { ElMessage } from 'element-plus'

class AiWebsocket {
  constructor(arg) {
    this.itemID = arg.itemID;
    this.Color = arg.Color;
    this.xb_userid = arg.xb_userid;
    this.f_password = arg.f_password;
    this.socketTask = null;
    this.gameKey = undefined;
  }
  /*
    连接服务器socket
  */
  connect() {
    this.socketTask = new WebSocket('ws://hall.career.com.cn:41000/ws')
    this.socketTask.binaryType = 'arraybuffer'

    this.socketTask.onopen = (data) => {
      console.log('success>>>>>>>>>WQ');
    };
    this.socketTask.onerror = (err) => {
      console.log("err:" + err);
    };

    this.socketTask.onmessage = (res) => {
      console.log('收到服务器回值')
      let content = new Int8Array(res.data);
      // console.log(content)
      this.treateData(content);
    };

    this.socketTask.onclose = res => {
      // console.log(res)
      console.log('WebSocket 已关闭！>>>>>>>>onSocketClose');

    }
  }



  /*
   断开连接
  */
  unconnect() {
    this.socketTask.close()
  }


  /*
   发送信息
  */
  sendSocketMessage(buffer) {
    this.socketTask.send(buffer)
  }

  login() {
    let buf = null;
    buf = xbtool.utils.WriteString(buf, this.xb_userid);
    buf = xbtool.utils.WriteString(buf, this.f_password);
    // console.log(this.xb_userid, this.f_password)
    buf = xbtool.utils.WriteCmdToBuffer(buf, 1);
    this.sendSocketMessage(buf);
  }


  //申请一盘对局
  applyGame(itemID = this.itemID) {
    // console.log('申请一盘对局')
    let buf = null;
    // console.log(itemID)
    buf = xbtool.utils.WriteInt32(buf, itemID);
    buf = xbtool.utils.WriteInt8(buf, this.Color);
    buf = xbtool.utils.WriteCmdToBuffer(buf, 51);
    // console.log(buf)
    this.sendSocketMessage(buf)
  }

  //用户落子
  luoZi(data) {
    // console.log(data)
    // console.log(this.gameKey)
    let buf = null;
    buf = xbtool.utils.WriteString(buf, this.gameKey);
    buf = xbtool.utils.WriteString(buf, data.stepStr);
    buf = xbtool.utils.WriteCmdToBuffer(buf, 52);
    // console.log(buf)
    this.sendSocketMessage(buf);
  }

  //认输
  giveUp() {
    // console.log(this.gameKey)
    let buf = null;
    buf = xbtool.utils.WriteString(buf, this.gameKey);
    buf = xbtool.utils.WriteCmdToBuffer(buf, 53);
    // console.log(buf)
    this.sendSocketMessage(buf);
  }

  //形势判断1，申请数棋2
  getXingShiData(data) {
    let buf = null;
    buf = xbtool.utils.WriteString(buf, this.gameKey);
    buf = xbtool.utils.WriteInt8(buf, data.ope);
    buf = xbtool.utils.WriteCmdToBuffer(buf, 54);
    this.sendSocketMessage(buf);
  }

  //悔棋
  withdraw() {
    let buf = null;
    buf = xbtool.utils.WriteString(buf, this.gameKey);
    buf = xbtool.utils.WriteCmdToBuffer(buf, 55);
    this.sendSocketMessage(buf);
  }

  treateData(content) {
    // console.log(content)
    let LineData = new xbtool.LineData(content, 2);
    let cmd = LineData.ReadInt8();
    // console.log(cmd)
    if (cmd == 0) {
      this.login();
      return
    } else if (cmd == 3) {
      this.applyGame();
      return;
    }
    // cmd == 51 表示和AI 分级有关的指令，再次解析一个byte 来确定具体的内容
    if (cmd != 51) {
      return;
    }


    let innerCmd = LineData.ReadInt8();
    // console.log(innerCmd)
    switch (innerCmd) {
      case 1:
        this.receiveNewGameInfo(LineData)
        break
      case 2:
        this.receiveLuoZiData(LineData)
        break
      case 3:
        this.receiveGameEnding(LineData)
        break
      case 4:
        this.receiveXingShiData(LineData)
        break
      case 5:
        this.receiveWithdrawRes(LineData)
        break
    }


  }

  receiveNewGameInfo(LineData) {
    // console.log(LineData)
    let gameInfo = {};
    gameInfo.ItemID = LineData.ReadInt32();
    gameInfo.BoardSize = LineData.ReadInt8();
    gameInfo.Color = LineData.ReadInt8();
    gameInfo.GameKey = LineData.ReadString();
    gameInfo.BlackT = LineData.ReadInt32();
    gameInfo.WhiteT = LineData.ReadInt32();
    gameInfo.StepStr = LineData.ReadLongString();
    gameInfo.GameType = LineData.ReadInt8();

    this.gameKey = gameInfo.GameKey
    // console.log(gameInfo, '~~~~~~~~')

    uni.emit('gameInfo', gameInfo)
  }

  receiveLuoZiData(LineData) {
    let luoZiData = {};
    luoZiData.GameKey = LineData.ReadString();
    luoZiData.StepStr = LineData.ReadString();
    luoZiData.BlackT = LineData.ReadInt32();
    luoZiData.WhiteT = LineData.ReadInt32();
    // console.log(luoZiData)
    uni.emit('LuoZiData', luoZiData)
  }

  receiveGameEnding(LineData) {
    let GameEndRes = {};
    GameEndRes.GameKey = LineData.ReadString();
    GameEndRes.ResultStr = LineData.ReadString();
    // console.log(GameEndRes)
    uni.emit('GameEnding', GameEndRes)
  }

  receiveXingShiData(LineData) {
    let xingShiData = {};
    xingShiData.GameKey = LineData.ReadString();
    xingShiData.JudgeData = {}
    xingShiData.JudgeData.b1 = LineData.ReadInt8();
    xingShiData.JudgeData.b2 = LineData.ReadInt8();
    if (xingShiData.JudgeData.b2 !== 0) {
      xingShiData.JudgeData.BoardSize = LineData.ReadInt8();
      let num = xingShiData.JudgeData.BoardSize * xingShiData.JudgeData.BoardSize;
      xingShiData.JudgeData.Data = Array.from(new Uint8Array(LineData.selfData.slice(LineData.GetCurrentPos(),
        LineData.GetCurrentPos() + num)));
      for (let i = 0; i < xingShiData.JudgeData.Data.length; i++) {
        if (xingShiData.JudgeData.Data[i] > 100) {
          xingShiData.JudgeData.Data[i] -= 200;
        }
      }
      LineData.resetPosition(LineData.GetCurrentPos() + num)
      xingShiData.JudgeData.BlackMu = LineData.ReadInt32()
      xingShiData.JudgeData.WhiteMu = LineData.ReadInt32()
      xingShiData.JudgeData.GuanZi = LineData.ReadInt32()
    }
    // console.log('形式判断', xingShiData)
    uni.emit('XingShiData', xingShiData)
  }


  receiveWithdrawRes(LineData) {
    let withdrawRes = {};
    withdrawRes.GameKey = LineData.ReadString();
    withdrawRes.UndoRes = LineData.ReadInt8();
    withdrawRes.ErrorNO = LineData.ReadInt32();
    // console.log(withdrawRes)
    uni.emit('WithdrawRes', withdrawRes)
  }

}

export default AiWebsocket
