// var Buffer = new ArrayBuffer();
function ab2str(buf) {
	return String.fromCharCode.apply(null, new Uint8Array(buf));
}

function TransferDToC(inNum) {
	var t = "";

	if (inNum > 7) {
		inNum = inNum + 1;
	}

	t = String.fromCharCode(inNum + 65);
	return t;
}

/*
   TransferCToD  将字母转成数字
*/
function TransferCToD(inC) {
	// 强制做下大小写转换，避免不必要的问题
	inC = inC.toUpperCase();
	var t = inC.charCodeAt(0);
	t = t - 65;
	if (t > 8) {
		t = t - 1;
	}

	if (t > 19) {
		t = -1;
	}

	return t;
}

/*
TranSmallint 将2个byte转成16位的整数...
 */
function TranSmallint(a, b) {
	if (a < 0) {
		a = 256 + a;
	}
	a = a; //& 0xff;
	b = b; //& 0xff;
	if (b >= 128) {
		b = b - 256;
	}
	b = b << 8;
	return a + b;
}

/*
   将一段Byte数组转成String....
*/
function BuffertoEnglish(buffer) {
	var m = 0;
	var s = "";
	for (var i = 0; i < buffer.byteLength; i++) {
		m = buffer[i] & 0xff;
		s = s + String.fromCharCode(m);
	}
	return s;
}

/*
  切割数组
*/
function Xinboslice(a, b, Int8Arr) {
	var resultarr = new Int8Array(b - a);
	var tmplength = b - a;
	for (var i = 0; i < tmplength; i++) {
		resultarr[i] = Int8Arr[i + a];
	}
	return resultarr;
}

/**
 * 封装一条数据，添加一个byte ,返回Buffer
 * @param {*} inBuffer
 * @param {*} inNum
 */
function WriteInt8(inBuffer, inNum) {
	let buffer = Buffer.alloc(1);
	buffer.writeUInt8(inNum);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/*
      封装第一条数据(2位的整数装成byte数组),返回值为Buffer
*/
function WriteInt16(inBuffer, inNum) {
	let buffer = Buffer.alloc(2);
	buffer.writeInt16LE(inNum);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}
/*
      封装第一条数据(将4位的整数装成byte数组),返回值为Buffer
*/
function WriteInt32(inBuffer, inNum) {
	let buffer = Buffer.alloc(4);
	buffer.writeInt32LE(inNum);

	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}
/*
      保存棋谱用的无符号
*/
function WriteUInt32(inBuffer, inNum) {
	let buffer = Buffer.alloc(4);
	buffer.writeUInt32LE(inNum);

	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}
/*
     将String 转成一个可以发送的数据包，长度为一个Byte
*/
function WriteString(inBuffer, inStr) {
	let head = Buffer.alloc(1);
	// console.log(typeof inStr)
	// console.log(inStr)
	// console.log(inStr instanceof Array)
	let body = Buffer.from(inStr);
	head.writeUInt8(inStr.length);
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		// console.log(buffer)
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		// console.log(buffer)
		return buffer;
	}
}

/**
 *
 * @param {*} inBuffer
 * @param {*} inStr
 * @param {*} len 1 2 4
 */
function WriteUtf8StringWithLen(inBuffer, inStr, len) {
	let body = Buffer.from(inStr)
	let head = null
	if (len == 1) {
		head = Buffer.alloc(1);
		head.writeUInt8(body.length);
	} else if (len == 2) {
		head = Buffer.alloc(2);
		head.writeUInt16LE(body.length);
	} else if (len == 4) {
		head = Buffer.alloc(4);
		head.writeUInt32LE(body.length);
	}
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入String ,不需要带string 的长度...
 * @param {*ArrayBuffer} inBuffer
 * @param {*string} inStr
 */
function WriteStringWithoutLen(inBuffer, inStr) {
	let body = Buffer.from(inStr);
	if (inBuffer == null) {
		return body;
	} else {
		let buffer = Buffer.concat([inBuffer, body])
		return buffer;
	}
}

/**
 * 写入String  长度为2个Byte....
 * @param {*} inBuffer
 * @param {*} inStr
 */
function WriteString2(inBuffer, inStr) {
	let head = Buffer.alloc(2);
	let body = Buffer.from(inStr);
	head.writeUInt16LE(inStr.length);
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入String  长度为4个Byte 且中文做了转码....
 * @param {*} inBuffer
 * @param {*} inStr
 */
function WriteString3(inBuffer, inStr) {
	let head = Buffer.alloc(4);
	head.writeUInt32LE(inStr.length);

	let body = Buffer.alloc(inStr.length);
	for (var i = 0; i < inStr.length; i++) {
		var ii = inStr.charCodeAt(i);
		body.writeUInt8(ii, i);
	}

	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}


/**
 * 写入中文...
 * */
function WriteChineseString(inBuffer, inStr) {
	let head = Buffer.alloc(1);
	head.writeUInt8(inStr.length * 2);
	let body = Buffer.alloc(inStr.length * 2);
	for (var i = 0; i < inStr.length; i++) {
		var ii = inStr.charCodeAt(i);
		body.writeUInt16BE(ii, i * 2);
	}
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入中文，长度为2个字节...
 * @param {*} inBuffer
 * @param {*} inStr
 */
function WriteChineseString2(inBuffer, inStr) {
	let head = Buffer.alloc(2);
	head.writeUInt16LE(inStr.length * 2);
	let body = Buffer.alloc(inStr.length * 2);
	for (var i = 0; i < inStr.length; i++) {
		var ii = inStr.charCodeAt(i);
		body.writeUInt16BE(ii, i * 2);
	}
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入中文，长度为4个字节...
 * @param {*} inBuffer
 * @param {*} inStr
 */
function WriteChineseString4(inBuffer, inStr) {
	let head = Buffer.alloc(4);
	head.writeUInt32LE(inStr.length * 2);
	let body = Buffer.alloc(inStr.length * 2);
	for (var i = 0; i < inStr.length; i++) {
		var ii = inStr.charCodeAt(i);
		body.writeUInt16BE(ii, i * 2);
	}
	let buffer = Buffer.concat([head, body]);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入32位的浮点数...
 */
function WriteFloat32(inBuffer, inNum) {
	let buffer = Buffer.alloc(4);
	buffer.writeFloatLE(inNum);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入64位的浮点数...
 */
function WriteFloat64(inBuffer, inNum) {
	let buffer = Buffer.alloc(8);
	buffer.writeDoubleLE(inNum);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}

/**
 * 写入时间数据
 */
function WriteDateTime(inBuffer, inTime) {
	var theT = new Date("1970-01-01 00:00:00");
	var Num = inTime.getTime() - theT.getTime();
	let buffer = Buffer.alloc(8);
	Num = Num / (24 * 60 * 60 * 1000);
	Num = Num + 25569;
	// console.log(Num);
	buffer.writeDoubleLE(Num);
	if (inBuffer == null) {
		return buffer;
	} else {
		buffer = Buffer.concat([inBuffer, buffer]);
		return buffer;
	}
}


/**
 * 把cmd写进数据里面...
 * @param {*ArrayBuffer} inBuffer
 * @param {*byte} cmd
 */
function WriteCmdToBuffer(inBuffer, cmd) {
	let buffer = Buffer.alloc(1);
	buffer.writeUInt8(cmd);
	let head = Buffer.alloc(2);
	if (inBuffer == null) {
		head.writeInt16LE(1);
		buffer = Buffer.concat([head, buffer]);
	} else {
		head.writeInt16LE(1 + inBuffer.byteLength);
		buffer = Buffer.concat([head, buffer]);
		buffer = Buffer.concat([buffer, inBuffer]);
	}
	return buffer;
}

/**
 * 把cmd写进数据里面，这里长度位为4
 * @param {*ArrayBuffer} inBuffer
 * @param {*byte} cmd
 */
function WriteCmdToLongBuffer(inBuffer, cmd) {
	let buffer = Buffer.alloc(1);
	buffer.writeUInt8(cmd);
	let head = Buffer.alloc(4);
	if (inBuffer == null) {
		head.writeInt32LE(1);
		buffer = Buffer.concat([head, buffer]);
	} else {
		head.writeInt32LE(1 + inBuffer.byteLength);
		buffer = Buffer.concat([head, buffer]);
		buffer = Buffer.concat([buffer, inBuffer]);
	}
	return buffer;
}

/*
      封装指令的函数
*/
function CmdPackage(cmd, RArr) {
	if (cmd == null) {
		return;
	} else if (RArr != null) {
		var DataArr = RArr;
		if (DataArr == " ") {
			return;
		} else {
			DataArr.unshift(cmd);
			var Len = DataArr.length;
			var LenArr = utils.SmallIntCode(Len);
			DataArr.unshift(LenArr[1]);
			DataArr.unshift(LenArr[0]);
			// console.log("DataArr2222:" + DataArr);
			return DataArr;
		}
	}
}




/*
  将String 转成一个可以发送的数据包....
*/
function EncodeSendPackage(inStr) {
	// let head = new Buffer(2);
	// let body = new Buffer(inStr);
	let head = Buffer.alloc(2);
	let body = Buffer.from(inStr);
	head.writeInt16LE(body.byteLength, 0);
	let buffer = Buffer.concat([head, body]);
	return new Uint8Array(buffer);
}

/*
  将String 转成一个可以发送的数据包，长度为一个Byte
 */
function EncodeStringToArray(inStr) {
	let head = Buffer.alloc(1);
	let body = Buffer.from(inStr);
	head.writeUInt8(inStr.length);
	let buffer = Buffer.concat([head, body]);
	return new Uint8Array(buffer);
}

/*
     将一段Buffer的内容，放在Uint8Array数组的后面去..
     inputArray  输入的Uint88Array
     inputBuffer 输入的Buffer
*/
function ReadABufferToLast(inputArray, inputBuffer) {
	var ab = new ArrayBuffer(inputBuffer.byteLength);
	var view = new Uint8Array(ab);
	for (var i = 0; i < inputBuffer.byteLength; i++) {
		view[i] = inputBuffer[i];
	}
	if (inputArray == "") {
		return view;
	} else {
		var newArray = new Uint8Array(inputArray.byteLength + inputBuffer.byteLength);
		for (var i = 0; i < inputArray.byteLength; i++) {
			newArray[i] = inputArray[i];
		}
		for (var j = 0; j < inputBuffer.byteLength; j++) {
			newArray[j + inputArray.byteLength] = view[j];
		}
		return newArray;
	}

}

/**
 *  SmallIntCode 将一个数字转成2个Byte
 */
function SmallIntCode(inNumber) {
	var tmpArr = new Array();
	if (inNumber < 256) {
		tmpArr[0] = inNumber;
		tmpArr[1] = 0;
	} else {
		tmpArr[0] = inNumber % 256;
		tmpArr[1] = Math.floor(inNumber / 256);
	}
	return tmpArr;
}

/**
 * 将4位的整数装成byte数组
 * @param {*} inNumber
 * 返回值为 array
 */
function LongIntCode(inNumber) {
	var tmpArr = new Array();
	var a = inNumber % (256 * 256 * 256);
	tmpArr[3] = Math.floor(inNumber / (256 * 256 * 256));
	tmpArr[2] = Math.floor(a / (256 * 256));
	a = a % (256 * 256);
	tmpArr[1] = Math.floor(a / 256);
	tmpArr[0] = a % 256;
	return tmpArr;
}
/*
      TranLongint  将4个Byte转换成一个整数(32位的)
*/
function TranLongint(a, b, c, d) {
	a = a & 0xff;
	b = b & 0xff;
	b = b << 8;
	c = c & 0xff;
	d = d & 0xff;
	c = c << 16;
	d = d << 24;
	return a + b + c + d;
}
/*
      TranLongint  将1个Byte转换成一个整数(8位的)
*/
function TranOneInt(a) {
	a = a & 0xff;
	return a;
}

function GetRankByScore(inScore) {
	inScore = Number(inScore);
	if (inScore < 200) {
		return '25级';
	} else
	if (inScore < 400) {
		return '24级';
	} else
	if (inScore < 600) {
		return '23级';
	} else
	if (inScore < 800) {
		return '22级';
	} else
	if (inScore < 1000) {
		return '21级';
	} else
	if (inScore < 1200) {
		return '20级';
	} else
	if (inScore < 1400) {
		return '19级';
	} else
	if (inScore < 1600) {
		return '18级';
	} else
	if (inScore < 1800) {
		return '17级';
	} else
	if (inScore < 2000) {
		return '16级';
	} else
	if (inScore < 2200) {
		return '15级';
	} else
	if (inScore < 2400) {
		return '14级';
	} else
	if (inScore < 2600) {
		return '13级';
	} else
	if (inScore < 2800) {
		return '12级';
	} else
	if (inScore < 3000) {
		return '11级';
	} else
	if (inScore < 3200) {
		return '10级';
	} else
	if (inScore < 3400) {
		return '9级';
	} else
	if (inScore < 3600) {
		return '8级';
	} else
	if (inScore < 3800) {
		return '7级';
	} else
	if (inScore < 4000) {
		return '6级';
	} else
	if (inScore < 4200) {
		return '5级';
	} else
	if (inScore < 4400) {
		return '4级';
	} else
	if (inScore < 4600) {
		return '3级';
	} else
	if (inScore < 4800) {
		return '2级';
	} else
	if (inScore < 5000) {
		return '1级';
	} else
	if (inScore < 5200) {
		return '1段';
	} else
	if (inScore < 5400) {
		return '2段';
	} else
	if (inScore < 5600) {
		return '3段';
	} else
	if (inScore < 5800) {
		return '4段';
	} else
	if (inScore < 6000) {
		return '5段';
	} else
	if (inScore < 6200) {
		return '6段';
	} else
	if (inScore < 6400) {
		return '7段';
	} else
	if (inScore < 6600) {
		return '8段';
	} else
	if (inScore < 6800) {
		return '9段';
	} else
	if (inScore < 7000) {
		return '初段';
	} else
	if (inScore < 7200) {
		return '二段';
	} else
	if (inScore < 7400) {
		return '三段';
	} else
	if (inScore < 7600) {
		return '四段';
	} else
	if (inScore < 7800) {
		return '五段';
	} else
	if (inScore < 8000) {
		return '六段';
	} else
	if (inScore < 8200) {
		return '七段';
	} else
	if (inScore < 8400) {
		return '八段';
	} else
	if (inScore < 8600) {
		return '九段';
	} else
		return '25级';
}

function GetScoreByRank(inStr) {
	if (inStr == "25级" || inStr == "25k") {
		return 100;
	} else if (inStr == "24级" || inStr == "24k") {
		return 300;
	} else if (inStr == "23级" || inStr == "23k") {
		return 500;
	} else if (inStr == "22级" || inStr == "22k") {
		return 700;
	} else if (inStr == "21级" || inStr == "21k") {
		return 900;
	} else if (inStr == "20级" || inStr == "20k") {
		return 1100;
	} else if (inStr == "19级" || inStr == "19k") {
		return 1300;
	} else if (inStr == "18级" || inStr == "18k") {
		return 1500;
	} else if (inStr == "17级" || inStr == "17k") {
		return 1700;
	} else if (inStr == "16级" || inStr == "16k") {
		return 1900;
	} else if (inStr == "15级" || inStr == "15k") {
		return 2100;
	} else if (inStr == "14级" || inStr == "14k") {
		return 2300;
	} else if (inStr == "13级" || inStr == "13k") {
		return 2500;
	} else if (inStr == "12级" || inStr == "12k") {
		return 2700;
	} else if (inStr == "11级" || inStr == "11k") {
		return 2900;
	} else if (inStr == "10级" || inStr == "10k") {
		return 3100;
	} else if (inStr == "9级" || inStr == "9k") {
		return 3300;
	} else if (inStr == "8级" || inStr == "8k") {
		return 3500;
	} else if (inStr == "7级" || inStr == "7k") {
		return 3700;
	} else if (inStr == "6级" || inStr == "6k") {
		return 3900;
	} else if (inStr == "5级" || inStr == "5k") {
		return 4100;
	} else if (inStr == "4级" || inStr == "4k") {
		return 4300;
	} else if (inStr == "3级" || inStr == "3k") {
		return 4500;
	} else if (inStr == "2级" || inStr == "2k") {
		return 4700;
	} else if (inStr == "1级" || inStr == "1k") {
		return 4900;
	} else if (inStr == "1段" || inStr == "1d") {
		return 5100;
	} else if (inStr == "2段" || inStr == "2d") {
		return 5300;
	} else if (inStr == "3段" || inStr == "3d") {
		return 5500;
	} else if (inStr == "4段" || inStr == "4d") {
		return 5700;
	} else if (inStr == "5段" || inStr == "5d") {
		return 5900
	} else if (inStr == "6段" || inStr == "6d") {
		return 6100;
	} else if (inStr == "7段" || inStr == "7d") {
		return 6300;
	} else if (inStr == "8段" || inStr == "8d") {
		return 6500;
	} else if (inStr == "9段" || inStr == "9d") {
		return 6700;
	} else {
		return 100;
	}
}

/**
 * 把一个棋盘数组转成长度为95的数组...
 * @param {*} inBoard
 */
function ZipABoard(inBoard) {
	var BZ = inBoard.length; // this is board size...
	var tmpBoard = new Array();
	for (var i = 0; i < 19; i++) {
		tmpBoard[i] = new Array();
		for (var j = 0; j < 19; j++) {
			tmpBoard[i][j] = 0;
		}
	}
	// 把当前的棋盘Copy进去...
	for (var i = 0; i < BZ; i++) {
		for (var j = 0; j < BZ; j++) {
			tmpBoard[i][j] = inBoard[i][j];
		}
	}
	// 开始组装数据..
	var ResArr = new Array;
	for (var i = 0; i < 95; i++) {
		ResArr[i] = 0;
	}

	var k = 0;

	for (var i = 0; i < 19; i++) {
		var tmpByte = 0;
		var a1 = tmpBoard[0][i];
		var a2 = tmpBoard[1][i];
		var a3 = tmpBoard[2][i];
		var a4 = tmpBoard[3][i];
		tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6);
		ResArr[k] = tmpByte;
		k++;

		a1 = tmpBoard[4][i];
		a2 = tmpBoard[5][i];
		a3 = tmpBoard[6][i];
		a4 = tmpBoard[7][i];
		tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6);
		ResArr[k] = tmpByte;
		k++;

		a1 = tmpBoard[8][i];
		a2 = tmpBoard[9][i];
		a3 = tmpBoard[10][i];
		a4 = tmpBoard[11][i];
		tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6);
		ResArr[k] = tmpByte;
		k++;

		a1 = tmpBoard[12][i];
		a2 = tmpBoard[13][i];
		a3 = tmpBoard[14][i];
		a4 = tmpBoard[15][i];
		tmpByte = a1 + (a2 << 2) + (a3 << 4) + (a4 << 6);
		ResArr[k] = tmpByte;
		k++;

		a1 = tmpBoard[16][i];
		a2 = tmpBoard[17][i];
		a3 = tmpBoard[18][i];
		tmpByte = a1 + (a2 << 2) + (a3 << 4);
		ResArr[k] = tmpByte;
		k++;
	}

	if (BZ < 19) {
		ResArr[94] = 99 + BZ;
	}

	return ResArr;
}

/**
 * 把一个长度为95的数组，解析成一个二维棋谱数组...
 * @param {*} board
 */
function UnzipBoard(board) {
	var boardsize;
	var tmpboard = new Array();
	var a = 0;
	var b = 0;
	var s = 0;
	if (board.length == 95) {
		if (board[94] > 42) {
			boardsize = board[94] - 99;
		} else {
			boardsize = 19;
		}
		for (var i = 0; i < 19; i++) {
			tmpboard[i] = new Array();
		}
		for (var i = 0; i < 19; i++) {

			if (i < 19) {
				for (var j = 0; j < 5; j++) {
					if (j * 4 < 19) {
						a = board[i * 5 + j] & 0xff;
						for (var z = 0; z < 4; z++) {
							if (j * 4 + z < 19) {
								if (j == 4) {
									if (z == 0) {
										b = a >> 4;
										a = a % 16;
										s = parseInt(j * 4 + z + 2, 10);
									} else
									if (z == 1) {
										b = a >> 2;
										a = a % 4;
										s = parseInt(j * 4 + z, 10);
									} else
									if (z == 2) {
										b = a;
										s = parseInt(j * 4 + z - 2, 10);
									}

								} else {
									if (z == 0) {
										b = a >> 6;
										a = a % 64;
										s = parseInt(j * 4 + z + 3, 10);
									} else
									if (z == 1) {
										b = a >> 4;
										a = a % 16;
										s = parseInt(j * 4 + z + 1, 10);
									} else
									if (z == 2) {
										b = a >> 2;
										a = a % 4;
										s = parseInt(j * 4 + z - 1, 10);
									} else
									if (z == 3) {
										b = a;
										s = parseInt(j * 4 + z - 3, 10);
									}

								}

								i = parseInt(i, 10);
								tmpboard[s][i] = b;
							}
						}
					}
				}
			}
		}


		if (boardsize == 19) {
			return tmpboard;
		} else {
			var tmpboard2 = new Array();
			for (var i = 0; i < boardsize; i++) {
				tmpboard2[i] = new Array();
			}
			for (var i = 0; i < boardsize; i++) {
				for (var j = 0; j < boardsize; j++) {
					tmpboard2[i][j] = tmpboard[i][j];
				}
			}
			return tmpboard2;
		}


	}
	return "error board";
}

function GetPhotoChange(params) {
	//头像格式转换
	params = Number(params);
	if (params > 500 && params <= 600) {
		params -= 500;
		params = "silver" + params.toString();
	} else if (params > 600 && params <= 700) {
		params -= 600;
		params = "golden" + params.toString();
		params = 2;
	} else if (params > 700 && params <= 800) {
		params -= 700;
		params = "red" + params.toString();
	}
	params =
		"http://utf8.xinboduiyi.com/go/img/" + params + ".png";
	return params;
}

function getScoreRank(inStr) {
	var score = {};
	if (inStr == "25级" || inStr == "25k") {
		score.minScore = 0;
		score.maxScore = 199;
		return score;
	} else if (inStr == "24级" || inStr == "24k") {
		score.minScore = 200;
		score.maxScore = 399;
		return score;
	} else if (inStr == "23级" || inStr == "23k") {
		score.minScore = 400;
		score.maxScore = 599;
		return score;
	} else if (inStr == "22级" || inStr == "22k") {
		score.minScore = 600;
		score.maxScore = 799;
		return score;
	} else if (inStr == "21级" || inStr == "21k") {
		score.minScore = 800;
		score.maxScore = 999;
		return score;
	} else if (inStr == "20级" || inStr == "20k") {
		score.minScore = 1000;
		score.maxScore = 1199;
		return score;
	} else if (inStr == "19级" || inStr == "19k") {
		score.minScore = 1200;
		score.maxScore = 1399;
		return score;
	} else if (inStr == "18级" || inStr == "18k") {
		score.minScore = 1400;
		score.maxScore = 1599;
		return score;
	} else if (inStr == "17级" || inStr == "17k") {
		score.minScore = 1600;
		score.maxScore = 1799;
		return score;
	} else if (inStr == "16级" || inStr == "16k") {
		score.minScore = 1800;
		score.maxScore = 1999;
		return score;
	} else if (inStr == "15级" || inStr == "15k") {
		score.minScore = 2000;
		score.maxScore = 2199;
		return score;
	} else if (inStr == "14级" || inStr == "14k") {
		score.minScore = 2200;
		score.maxScore = 2399;
		return score;
	} else if (inStr == "13级" || inStr == "13k") {
		score.minScore = 2400;
		score.maxScore = 2599;
		return score;
	} else if (inStr == "12级" || inStr == "12k") {
		score.minScore = 2600;
		score.maxScore = 2799;
		return score;
	} else if (inStr == "11级" || inStr == "11k") {
		score.minScore = 2800;
		score.maxScore = 2999;
		return score;
	} else if (inStr == "10级" || inStr == "10k") {
		score.minScore = 3000;
		score.maxScore = 3199;
		return score;
	} else if (inStr == "9级" || inStr == "9k") {
		score.minScore = 3200;
		score.maxScore = 3399;
		return score;
	} else if (inStr == "8级" || inStr == "8k") {
		score.minScore = 3400;
		score.maxScore = 3599;
		return score;
	} else if (inStr == "7级" || inStr == "7k") {
		score.minScore = 3600;
		score.maxScore = 3799;
		return score;
	} else if (inStr == "6级" || inStr == "6k") {
		score.minScore = 3800;
		score.maxScore = 3999;
		return score;
	} else if (inStr == "5级" || inStr == "5k") {
		score.minScore = 4000;
		score.maxScore = 4199;
		return score;
	} else if (inStr == "4级" || inStr == "4k") {
		score.minScore = 4200;
		score.maxScore = 4399;
		return score;
	} else if (inStr == "3级" || inStr == "3k") {
		score.minScore = 4400;
		score.maxScore = 4599;
		return score;
	} else if (inStr == "2级" || inStr == "2k") {
		score.minScore = 4600;
		score.maxScore = 4799;
		return score;
	} else if (inStr == "1级" || inStr == "1k") {
		score.minScore = 4800;
		score.maxScore = 4999;
		return score;
	} else if (inStr == "1段" || inStr == "1d") {
		score.minScore = 5000;
		score.maxScore = 5199;
		return score;
	} else if (inStr == "2段" || inStr == "2d") {
		score.minScore = 5200;
		score.maxScore = 5399;
		return score;
	} else if (inStr == "3段" || inStr == "3d") {
		score.minScore = 5400;
		score.maxScore = 5599;
		return score;
	} else if (inStr == "4段" || inStr == "4d") {
		score.minScore = 5600;
		score.maxScore = 5799;
		return score;
	} else if (inStr == "5段" || inStr == "5d") {
		score.minScore = 5800;
		score.maxScore = 5999;
		return score;
	} else if (inStr == "6段" || inStr == "6d") {
		score.minScore = 6000;
		score.maxScore = 6199;
		return score;
	} else if (inStr == "顶阶") {
		score.minScore = 6200;
		score.maxScore = 6800;
		return score;
	}
}

function DeepCopy(a1, a2, ww) {
	for (var i = 0; i <= ww; i++) {
		for (var j = 0; j <= ww; j++) {
			a1[i][j] = a2[i][j];
		}
	}
}

function DeepCopy2(a1, a2, ww) {
	for (var i = 0; i <= ww; i++) {
		a1[i] = a2[i];
	}
}

module.exports = {
	ab2str,
	TransferDToC,
	TransferCToD,
	TranSmallint,
	BuffertoEnglish,
	Xinboslice,
	WriteInt8,
	WriteInt16,
	WriteInt32,
	WriteUInt32,
	WriteString,
	WriteStringWithoutLen,
	WriteString2,
	WriteString3,
	WriteFloat32,
	WriteFloat64,
	WriteDateTime,
	WriteChineseString,
	WriteChineseString2,
	WriteChineseString4,
	CmdPackage,
	EncodeSendPackage,
	EncodeStringToArray,
	ReadABufferToLast,
	SmallIntCode,
	LongIntCode,
	TranLongint,
	WriteCmdToBuffer,
	WriteCmdToLongBuffer,
	TranOneInt,
	GetRankByScore,
	GetScoreByRank,
	ZipABoard,
	UnzipBoard,
	GetPhotoChange,
	getScoreRank,
	DeepCopy,
	DeepCopy2,
	WriteUtf8StringWithLen
}
