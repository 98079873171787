import { createStore } from 'vuex'
import AIChallenge from '@/service/socket/AIChallenge.js'
const store = createStore({
  state() {
    return {
      /*
      websocket实例对象
      */
      mainSocket: null,  //ai闯关的socket
      sessionid: '',
    }
  },
  getters: {},
  mutations: {
    //登录的id
    // there_are(state, num) {
    //   state.there_are = num
    //   sessionStorage.setItem('there_are', num)
    // },
    //实例化socket对象
    startAISocket(state, payload) {
      state.mainSocket = new AIChallenge(payload)
    }
  },
  actions: {},
  modules: {
  }
})

export function setupStore() {
  // store.dispatch('login/getStorageLogin')
}


export default store
