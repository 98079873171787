class TTCPClient {
    constructor(ip,port,withIDN=false) {
        this.serverip = ip;
        this.serverport = port;
        this.ConnectStatus = 0;
        this.receiveBuffer = "";
        this.withIDN = withIDN;
        this.IDNTreated = false;   // 当前是否已经处理了IDN...
        this.SelfTimer = null;  // 本地的一个Timer，用于断线的时候，进行断线重连
        let EventEmitter = require('events').EventEmitter
        this.listenerList = new EventEmitter();
        this.StartToWork();
    }

    destroy(){

    }
     
    /**
     * 开始进行连接
     */
    StartToWork(){
        this.ConnectStatus = 0;
        
        let net = require("net");
        this.client = new net.Socket;
        this.client.connect(this.serverport, this.serverip, ()=>{
            this.ConnectStatus = 1;
        });

        this.client.on("data",(data)=>{
            // 收到了数据....
            this.ReceiveData(data);
            while (true) {
                var RArr = this.ReadAPackage();
                if (RArr == null) {
                    break;
                }
                this.trigger("data", RArr);
            }
        });

        this.client.on("error",(err)=>{
            if (this.ConnectStatus != 2) {
                if (this.ConnectStatus == 1) {
                    this.trigger("error");
                } else {
                    this.trigger("fail");
                }
                this.ConnectStatus = 2;
                // this.SelfTimer = setInterval(()=>{
                //     clearInterval(this.SelfTimer);
                //     this.StartToWork(); 
                // }, 5000);
            }
        });

        this.client.on("close",()=>{
            if (this.ConnectStatus != 2) {
                if (this.ConnectStatus == 1) {
                    this.trigger("error");
                }
                this.ConnectStatus = 2;
                // this.SelfTimer = setInterval(()=>{
                //     clearInterval(this.SelfTimer);
                //     this.StartToWork(); 
                // }, 5000);
            }
        });
    }

    /**
     * 发送数据,传入的是一个Buffer
     * @param {*} inBuf 
     */
    SendData(inBuf){
        if (this.ConnectStatus != 1) {
            return;
        }
        this.client.write(inBuf);
    }

    /**
     * 获取TCP的连接状态
     */
    GetConnectionStatus(){
        return this.ConnectStatus;
    }
    
    /**
     * 收到一段数据...
     * @param {*} inData 
     */
    ReceiveData(inData) {
        if (this.withIDN && !this.IDNTreated) {
            //如果需要处理IDN, 那么线处理idn，然后在处理流模式的指令...
            this.receiveBuffer = this.receiveBuffer + inData;
            // 查看下最后是不是13
            if (this.receiveBuffer == "") {
                return
            }
            if (this.receiveBuffer.charCodeAt(this.receiveBuffer.length-1) != 13) {
                return
            }
            
            var idnStr = this.receiveBuffer.match(/IDN:(\S*):NDI/)[1];
            var decodeStr = this.decodeIDN(idnStr);
            var S = "300001 "+decodeStr + String.fromCharCode(13);
            this.client.write(S);
            this.IDNTreated = true;
            this.receiveBuffer = "";
            return;
        }
        var ab = new ArrayBuffer(inData.byteLength);
        var view = new Uint8Array(ab);                      //根据ad长度建立一个8位数组
        for (var i=0;i<inData.byteLength;i++) {
            view[i] = inData[i];
        }

        if (this.receiveBuffer == "") {
            this.receiveBuffer = view;
        } else {
            var newArray = new Uint8Array(this.receiveBuffer.byteLength + inData.byteLength);
            for (var i=0;i<this.receiveBuffer.byteLength;i++) {
                newArray[i] = this.receiveBuffer[i];
            }
            for (var j=0;j<inData.byteLength;j++) {
                newArray[j + this.receiveBuffer.byteLength] = view[j];
            }
            this.receiveBuffer = newArray;
        }
    }

    /**
     * 读取一个完整的数据包...
     */
    ReadAPackage(){
        if (this.withIDN && !this.IDNTreated) {
            return null;
        }
        if (this.receiveBuffer.byteLength < 2 || this.receiveBuffer == "") {
            return null;
        }
        // 读取头上的2个数字，转成一个整数...
        var _Len = this.ReadSmallInt();
        if (_Len == null) {
            return null;
        }
        if (this.receiveBuffer.byteLength < 2 + _Len) {
            return null;
        }
        var RArr = new Uint8Array(_Len+2);
        for (var i=0;i<_Len+2;i++) {
            RArr[i] = this.receiveBuffer[i];
        }
        // 对 ReceiveBuffer进行下截取操作...
        if (_Len + 2 == this.receiveBuffer.byteLength) {
            this.receiveBuffer = "";
        } else {
            var LeftLen = this.receiveBuffer.byteLength - _Len - 2;
            var LeftArr = new Uint8Array(LeftLen);
            for (var i=0;i<LeftLen;i++) {
                LeftArr[i] = this.receiveBuffer[_Len+2+i];
            }
            this.receiveBuffer = LeftArr;
        }
        return RArr;
    }

    /*
       从头部读取2位，并返回一个整数出来
    */
   ReadSmallInt(){
        if (this.receiveBuffer.byteLength < 2) {
            return null;
        }  
        var a = this.receiveBuffer[0];
        var b = this.receiveBuffer[1];
        if (a < 0) {
            a = a + 256;
        }  
        b = b << 8;
        return a + b;
    }

    /**
     * 留给外面注册事件用额
     * @param {*String 事件的名称} event 
     * @param {*对应的函数} listener 
     */
    on(event, listener) {
        if(event !== 'error') {
            this.listenerList.addListener(event, listener);
        } else {
            this.listenerList.addListener('internalError', listener);
        }
    }
  
    /**
     * 内部触发一个事件的...
     * @param {* string 事件的名称} event 
     * @param  {...any} data 
     */
    trigger(event, ...data) {
        if(event !== 'error') {
            this.listenerList.emit(event, ...data);
        } else {
            this.listenerList.emit('internalError', ...data);
        }
    }

    /**
     * 反解析下IDN
     * @param {*string} S
     * @return string 
     */
    decodeIDN(S) {
        let S8 = []
        let bb = Number
        let SS = S
        let S1 = String
        let count = 0
        for (; count < 10;) {
            bb = Math.round(Math.random() * 1000)
            if (bb >= 0 && bb <= 255) {
                if (bb <= 32) {
                    bb += 128
                }
                S8.push(bb)
                count++
            }
        }
        S1 = SS[2] + SS[4] + SS[8]
        let k = parseInt(S1)
        if (typeof k != 'number') {
            k = 999
        }
        k *= 99999
        S1 = k.toString()
        let S2 = String
        let S3 = []
        for (; 1;) {
            if (S1 == "") {
                break
            }
            S2 = S1[0].toString()
            S1 = S1.substr(1) //删除字符串的第一位
            if (S1 != "") {
                S2 += S1[0].toString()
                S1 = S1.substr(1)
            }
            k = parseInt(S2)
            if (typeof k != 'number') {
                k = 0
            }
            if (k <= 32) {
                k += 128
            }
            S3.push(parseInt(k))
        }
        S8[1] = S3[0]
        S8[4] = S3[1]
        S8[7] = S3[2]
        if (S3.length > 3) {
            S8[8] = S3[3]
        }
        S2 = ""
        for (let i = 0; i < S8.length; i++) {
            let CH = parseInt(S8[i] / 17)
            let CL = parseInt(S8[i] % 17)
            S2 += String.fromCharCode(CH + 65) + String.fromCharCode(CL + 65)
        }
        return S2
    }

    /**
     * 关闭当前的连接
     */
    CloseTheSocket(){
        this.client.end();
    }
}

module.exports = TTCPClient;