// service统一出口
import ZxRequest from './request/index.js'
import { BASE_URL, TIME_OUT } from './request/config.js'

import { ElLoading } from 'element-plus'

let loadingInstance;//

const zxRequest = new ZxRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.4)'
      })
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败拦截-实例')
      return err
    },
    responseInterceptor: (res) => {
      // console.log('响应成功拦截-实例')
      loadingInstance.close()
      return res
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败拦截-实例')
      loadingInstance.close()
      return err
    }
  }
})
const AIRequest = new ZxRequest({
  baseURL: 'http://ailevel.xinboduiyi.com/api',
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // console.log('请求成功拦截-实例')
      loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.4)'
      })
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败拦截-实例')
      return err
    },
    responseInterceptor: (res) => {
      loadingInstance.close()

      // console.log('响应成功拦截-实例')
      return res
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败拦截-实例')
      loadingInstance.close()

      return err
    }
  }
})





export { zxRequest, AIRequest }
