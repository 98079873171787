

/**
 * 这里专门用于读取和分析一个数组的，配合我们的服务器数据使用
 */

class TSingleLineData {
  constructor(inData, startP) {
    this.selfData = inData;
    this._Pos = startP;
  }

  destroy() {
    this.selfData = null;
  }

  /**
   * 读取一位byte 
   */
  ReadInt8() {
    if (this._Pos >= this.selfData.byteLength) {
      return -1;
    }
    var rr = this.selfData[this._Pos] & 0xff;
    this._Pos++;
    return rr;
  }

  /**
   * 读取一个16位的整数
   */
  ReadInt16() {
    if (this._Pos + 2 > this.selfData.byteLength) {
      return -1;
    }
    var a = this.selfData[this._Pos] & 0xff;
    var b = this.selfData[this._Pos + 1] & 0xff;
    if (b >= 128) {
      b = b - 256;
    }
    b = b << 8;
    var rr = a + b;
    this._Pos = this._Pos + 2;
    return rr;
  }

  /**
   * 读取一个32位的整数
   */
  ReadInt32() {
    if (this._Pos + 4 > this.selfData.byteLength) {
      return -1;
    }
    var a = this.selfData[this._Pos];
    var b = this.selfData[this._Pos + 1];
    var c = this.selfData[this._Pos + 2];
    var d = this.selfData[this._Pos + 3];
    a = a & 0xff;
    b = b & 0xff;
    b = b << 8;
    c = c & 0xff;
    d = d & 0xff;
    c = c << 16;
    d = d << 24;
    var rr = a + b + c + d;
    this._Pos = this._Pos + 4;
    return rr;
  }

  /**
   * 读取一个64位的整数...
   */
  ReadInt64() {
    if (this._Pos + 8 > this.selfData.byteLength) {
      return -1;
    }
    var a = this.selfData[this._Pos];
    var b = this.selfData[this._Pos + 1];
    var c = this.selfData[this._Pos + 2];
    var d = this.selfData[this._Pos + 3];
    var e = this.selfData[this._Pos + 4];
    var f = this.selfData[this._Pos + 5];
    var g = this.selfData[this._Pos + 6];
    var h = this.selfData[this._Pos + 7];
    a = a & 0xff;
    b = b & 0xff;
    b = b << 8;
    c = c & 0xff;
    d = d & 0xff;
    c = c << 16;
    d = d << 24;
    e = e & 0xff;
    e = e << 32;
    f = f & 0xff;
    f = f << 40;
    g = g & 0xff;
    g = g << 48;
    h = h & 0xff;
    h = h << 56;
    var rr = a + b + c + d + e + f + g + h;
    this._Pos = this._Pos + 8;
    return rr;
  }

  /**
   * 读取一段string,长度的标识位为1位
   */
  ReadString() {
    if (this._Pos + 1 >= this.selfData.byteLength) {
      return "";
    }
    var _Len = this.selfData[this._Pos] & 0xff;
    if (this._Pos + _Len >= this.selfData.byteLength) {
      return "";
    }
    this._Pos++;
    var s = "";
    var m;
    for (var i = 0; i < _Len; i++) {
      m = this.selfData[i + this._Pos] & 0xff;
      s = s + String.fromCharCode(m);
    }
    this._Pos = this._Pos + _Len;
    return s;
  }

  /**
   * 读取一段string,长度的标识位为2位
   */
  ReadLongString() {
    if (this._Pos + 2 >= this.selfData.byteLength) {
      return "";
    }
    var _Len = this.ReadInt16();
    if (this._Pos + _Len > this.selfData.byteLength) {
      return "";
    }
    var s = "";
    var m;
    for (var i = 0; i < _Len; i++) {
      m = this.selfData[i + this._Pos] & 0xff;
      s = s + String.fromCharCode(m);
    }
    this._Pos = this._Pos + _Len;
    return s;
  }

  /**
   *  读取一段string,长度的标识位为4位
   */
  ReadBigLongString() {
    if (this._Pos + 4 >= this.selfData.byteLength) {
      return "";
    }
    var _Len = this.ReadInt32();
    if (this._Pos + _Len > this.selfData.byteLength) {
      return "";
    }
    var s = "";
    var m;
    for (var i = 0; i < _Len; i++) {
      m = this.selfData[i + this._Pos] & 0xff;
      s = s + String.fromCharCode(m);
    }
    this._Pos = this._Pos + _Len;
    return s;
  }

  /**
   * 读取一段中文
   */
  ReadChineseStr() {
    if (this._Pos + 1 >= this.selfData.byteLength) {
      return "";
    }
    var _Len = this.selfData[this._Pos];
    if (this._Pos + _Len >= this.selfData.byteLength) {
      return "";
    }
    this._Pos++;
    var S = "";
    var m;
    var ta;
    var tb;
    var i = 0;
    while (i < _Len) {
      var a = this.selfData[i + this._Pos] & 0xff;
      var b = this.selfData[i + this._Pos + 1] & 0xff;
      i = i + 2;
      if (a.toString(16).length < 2) {
        ta = '0' + String(a.toString(16));
      } else {
        ta = String(a.toString(16));
      }
      if (b.toString(16).length < 2) {
        tb = '0' + String(b.toString(16));
      } else {
        tb = String(b.toString(16));
      }
      m = "\\u" + ta + tb;
      S = S + m;
    }
    this._Pos = this._Pos + _Len;

    S = unescape(S.replace(/\\/g, "%"));
    return S;
  }

  /**
   * 根据长度来读取一段中文...
   * 长度为2或者4
   * @param {*} inL 
   */
  ReadChineseWithLen(inL) {
    if (inL == 2) {
      var _Len = this.ReadInt16();
    } else if (inL == 4) {
      var _Len = this.ReadInt32();
    } else {
      return "";
    }
    if (this._Pos + _Len > this.selfData.byteLength) {
      return "";
    }
    var S = "";
    var m;
    var ta;
    var tb;
    var i = 0;
    while (i < _Len) {
      var a = this.selfData[i + this._Pos] & 0xff;
      var b = this.selfData[i + this._Pos + 1] & 0xff;
      i = i + 2;
      if (a.toString(16).length < 2) {
        ta = '0' + String(a.toString(16));
      } else {
        ta = String(a.toString(16));
      }
      if (b.toString(16).length < 2) {
        tb = '0' + String(b.toString(16));
      } else {
        tb = String(b.toString(16));
      }
      m = "\\u" + ta + tb;
      S = S + m;
    }
    this._Pos = this._Pos + _Len;

    S = unescape(S.replace(/\\/g, "%"));
    return S;
  }

  /**
   * 根据长度来读取一段UTF8的String...
   * 长度为 1 or 2 or 4
   * @param {*} inL 
   */
  readUTF8stringWithLen(inL) {
    var _Len;
    if (inL == 1) {
      _Len = this.ReadInt8();
    } else if (inL == 2) {
      _Len = this.ReadInt16();
    } else if (inL == 3) {
      _Len = this.ReadInt32();
    } else {
      return ""
    }
    if (_Len <= 0) {
      return ""
    }
    if (this._Pos + _Len > this.selfData.byteLength) {
      return "";
    }
    var i = 0;
    var S = "";
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    while (i < _Len) {
      c1 = this.selfData[i + this._Pos] & 0xff;
      if (c1 <= 128) {
        S = S + String.fromCharCode(c1);
        i++;
      } else if (c1 >= 129 && c1 <= 224) {
        if (i + 1 >= _Len) {
          break;
        }
        c2 = this.selfData[i + 1 + this._Pos] & 0xff;
        S += String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        if (i + 2 >= _Len) {
          break;
        }
        c2 = this.selfData[i + 1 + this._Pos] & 0xff;
        c3 = this.selfData[i + 2 + this._Pos] & 0xff;
        S += String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }
    this._Pos = this._Pos + _Len;
    return S;
  }

  /**
   * 从当前位置直到最后，读取成UTF8...
   */
  readUTF8StringToEnd() {
    var S = "";
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    while (this._Pos < this.selfData.byteLength) {
      c1 = this.selfData[this._Pos] & 0xff;
      if (c1 <= 128) {
        S = S + String.fromCharCode(c1);
        this._Pos++
      } else if (c1 >= 129 && c1 <= 224) {
        if (this._Pos + 1 >= this.selfData.byteLength) {
          break;
        }
        c2 = this.selfData[1 + this._Pos] & 0xff;
        S += String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
        this._Pos = this._Pos + 2;
      } else {
        if (this._Pos + 2 >= this.selfData.byteLength) {
          break;
        }
        c2 = this.selfData[1 + this._Pos] & 0xff;
        c3 = this.selfData[2 + this._Pos] & 0xff;
        S += String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        this._Pos = this._Pos + 3;
      }
    }
    return S;
  }

  /**
   * 重置当前的数据
   * @param {*输入的数据内容} inData 
   * @param {*当前的位置} pos 
   */
  resetData(inData, pos) {
    this.selfData = inData;
    this._Pos = pos;
  }

  /**
   * 获取当前的位置...
   */
  GetCurrentPos() {
    return this._Pos;
  }

  /**
   * 重新设置下当前的位置...
   * @param {*新的位置} pos 
   */
  resetPosition(pos) {
    this._Pos = pos;
  }

  /**
   * 从当前的位置开始，读取长度为_len长度的数据，返回一个ArrayBuffer数据
   * 读取好了后，把相应的位置往后移动...
   * 如果给的长度大于剩下的长度，就直接读到最后
   * 如果当前的位置，已经是最后了，就直接返回null
   * @param {*读取的长度} _len
   * @returns 相应的内容数据 UInt8Array 
   */
  ReadBufferFromCurrent(_len) {
    if (this._Pos >= this.selfData.byteLength) {
      return null;
    }
    var ALen;
    ALen = _len;
    if (this._Pos + _len > this.selfData.byteLength) {
      ALen = this.byteLength - this._Pos;
    }
    var RBuffer = [];
    for (var i = 0; i < ALen; i++) {
      var a = this.selfData[this._Pos + i] & 0xff;
      RBuffer.push(a);
    }
    this._Pos = this._Pos + ALen;
    return RBuffer;
  }

  /**
   * 读取一个32位的float数据...
   */
  readFloat32() {
    if (this._Pos + 4 > this.selfData.byteLength) {
      return -1;
    }
    var buffer = new ArrayBuffer(4);
    var int8Array = new Uint8Array(buffer);
    var F32Array = new Float32Array(buffer);
    for (var i = 0; i < 4; i++) {
      int8Array[i] = this.selfData[i + this._Pos] & 0xff;
    }
    this._Pos = this._Pos + 4;
    return F32Array[0];
  }

  /**
   * 读取一个双精度的小数...
   * 长度位 8
   */
  readDouble() {
    if (this._Pos + 8 > this.selfData.byteLength) {
      return -1;
    }

    var buffer = new ArrayBuffer(8);
    var int8Array = new Uint8Array(buffer);
    var F32Array = new Float64Array(buffer);
    for (var i = 0; i < 8; i++) {
      int8Array[i] = this.selfData[i + this._Pos] & 0xff;
    }
    this._Pos = this._Pos + 8;
    return F32Array[0];
  }

  /**
   * 读取一个
   */
  readDatetime() {
    if (this._Pos + 8 > this.selfData.byteLength) {
      return null;
    }
    var rr = this.readDouble();
    rr = rr - 25569;
    rr = rr * (24 * 60 * 60 * 1000);
    return new Date(rr);
  }

  /**
   * 读取一个时间，往前推8小时
   */
  readDatetime_electron() {
    if (this._Pos + 8 > this.selfData.byteLength) {
      return null;
    }
    var rr = this.readDouble();
    rr = rr - 25569;
    rr = rr * (24 * 60 * 60 * 1000);
    rr = rr - (8 * 60 * 60 * 1000);
    return new Date(rr);
  }

  /**
   * 读取中英文的混合string...
   */
  ReadMixString() {
    if (this._Pos + 2 > this.selfData.byteLength) {
      return "";
    }
    var _Len = this.ReadInt16();
    var S = "";
    for (var i = 1; i <= _Len; i++) {
      var ii = this.ReadInt16();
      S = S + String.fromCharCode(ii);
    }
    return S;
  }

}

module.exports = TSingleLineData;