<template>
  <div class="modal-bg">
    <div class="modal-container">
      <div class="box">
        <div class="loader">
          <div class="loading-3">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
      <div class="loading1">
        <span>加载中...</span>
      </div>
    </div>
  </div>
</template>
<script></script>
<style lang="scss" scoped>
@import '@/assets/css/rem.scss';
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  width: rem(325px);
  position: relative;
  z-index: 21;
  // border:1px solid red;
  //     .loader {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  .loading1 {
    font-size: rem(26px);
    width: rem(300px);
    position: absolute;
    left: 50%;
    margin-left: rem(-145px);
    margin-top: rem(-40px);
    text-align: center;
    color: #ff7e27;
  }
  .box {
    width: 100%;
    padding: 3%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box .loader {
    width: 30%;
    float: left;
    height: 200px;
    margin-right: 3%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @-webkit-keyframes loading-3 {
    50% {
      transform: scale(0.4);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .loading-3 {
    position: relative;
  }

  .loading-3 i {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ff7e27;
    position: absolute;
  }

  .loading-3 i:nth-child(1) {
    top: 25px;
    left: 0;
    -webkit-animation: loading-3 1s ease 0s infinite;
  }

  .loading-3 i:nth-child(2) {
    top: 17px;
    left: 17px;
    -webkit-animation: loading-3 1s ease -0.12s infinite;
  }

  .loading-3 i:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation: loading-3 1s ease -0.24s infinite;
  }

  .loading-3 i:nth-child(4) {
    top: -17px;
    left: 17px;
    -webkit-animation: loading-3 1s ease -0.36s infinite;
  }

  .loading-3 i:nth-child(5) {
    top: -25px;
    left: 0;
    -webkit-animation: loading-3 1s ease -0.48s infinite;
  }

  .loading-3 i:nth-child(6) {
    top: -17px;
    left: -17px;
    -webkit-animation: loading-3 1s ease -0.6s infinite;
  }

  .loading-3 i:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation: loading-3 1s ease -0.72s infinite;
  }

  .loading-3 i:nth-child(8) {
    top: 17px;
    left: -17px;
    -webkit-animation: loading-3 1s ease -0.84s infinite;
  }
}
</style>
