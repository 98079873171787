<template>
  <div class="leftmenu">
    <div class="left">
      <div class="logo">
        <img src="@/assets/images/leftmenu/logo.png"
             alt="">
      </div>
      <div class="leftbody">
        <div v-for="item in btninfo"
             :key="item.route">
          <img :src='imgUrl(item.url)'
               alt=""
               @click="toPath(item)">
        </div>
      </div>
      <div v-if="!success_login"
           class="login"
           @click="tologin">
        <img src="@/assets/images/registered/aj.png"
             alt="">
      </div>
      <div v-if="success_login"
           class="user">
           <div>
        <div class="touxiang">
          <img src="@/assets/images/touxiang.png" alt="">
          <p class="psd">Valid Period：{{times()}}</p>
          <el-link type="primary" @click="refresh">refresh</el-link>
          </div>
        </div>
        <div class="title">
          <p>{{info()}}</p>
          <p>{{infos()}}</p>
          <el-link type="primary" @click="exit">exit</el-link>
        </div>
      </div>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { ElLoading } from 'element-plus'
import { ref, computed,onMounted,created } from 'vue';
import { goods,getvali, logout } from '../../service/login/login';
import { useStore } from 'vuex';
import store from '../../store';
import emit from '@/utils/eventbus.js'

// const store = useStore()
// const toHome = (a) => {
//   router.push(a)
// }

//初始化显示按钮
const loaclUrl = window.location.href;
const btninfo = ref([]);
const stores = useStore();

//按钮的缓存保存在storage里

const initBtnInfo = () => {
  let loaclUrlArr = sessionStorage.getItem('selectBtn'),
    isFirst = false,
    // 'analysis', 'courses', 'classroom',
    tempArr = ['home', 'play',  'kifu', 'buystore'];
  // console.log(loaclUrlArr);
  tempArr.forEach((item) => {
    if (loaclUrlArr && loaclUrlArr.split('-')[0] === item) {
      (isFirst = true), (item = item + '-act');
    }
    btninfo.value.push({
      url: item,
      route: `./${item}`
    });
  });
  if (!isFirst) {
    btninfo.value[0].url = 'home-act';
  }
};
initBtnInfo();
//动态绑定img的src
const imgUrl = (url) => {
  return require(`@/assets/images/leftmenu/btn/${url}.png`);
};

//跳转路径
const toPath = (data) => {
  btninfo.value.forEach((item) => {
    //使原先被选中的变成正常状态
    if (item.url.split('-').length === 2) {
      item.url = item.url.split('-')[0];
    }
    //选中此次选中的
    if (item.route === data.route) {
      sessionStorage.setItem('selectBtn', item.url);
      item.url = data.url + '-act';
    }
  });
  data.route = data.route.split('-')[0];
  router.push(data.route);
};
const tologin = () => {
  router.push('./login');
};
const success_login=ref(false)
//登录成功显示个人信息
const there = () => {
  let sessid = sessionStorage.getItem('sessid');
  if (sessid != null) {
    success_login.value=true
    return true;
  }
  success_login.value=false
  return false;
};
there();
onMounted(()=>{
  emit.on('loginsuccess',there)
})
//获取名称
const info = () => {
  let data = sessionStorage.getItem('info');
  return JSON.parse(data).aliasname;
};
//获取级别
const infos = () => {
  let data = sessionStorage.getItem('info');
  return JSON.parse(data).level;
};
const times = () => {
  let refre=sessionStorage.getItem('refresh')
  if(!refre){
  let data = sessionStorage.getItem('info');
  return JSON.parse(data).validate;
  }else{
    return refre;
  }
};
//刷新过期时间
const  refresh =() => {
   let param={sessionid:sessionStorage.getItem('sessid')}
   if(param.sessionid!=null){
     getvali(param).then(res=>{
     if(res!=null){
      sessionStorage.setItem('refresh',res)
      location.reload();
    }else if(res=='login'){
       this.$router.push('./login')
       sessionStorage.removeItem('sessid')
       sessionStorage.removeItem('info')
     }
    })
   }
};
//退出
const exit=()=>{
  let params = new FormData()
  params.append('sessionid', sessionStorage.getItem('sessid'))
  logout(params).then(res=>{
    sessionStorage.removeItem('sessid')
    sessionStorage.removeItem('info')
    sessionStorage.removeItem('refresh')
    there()
  })
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/rem.scss';

.leftmenu {
  height: 100vh;
  padding: rem(30px);
  background: url(~@/assets/images/bj.png) repeat;
  background-size: cover;
  overflow-y: auto;
  display: flex;
  .left {
    margin-right: rem(30px);
    .logo {
      img {
        width: rem(152px);
        height: rem(113px);
      }
    }
    .leftbody {
      img {
        width: rem(224px);
        height: rem(76px);
        margin-bottom: rem(9px);
        cursor: pointer;
      }
      div:first-of-type img {
        margin-top: rem(23px);
      }
    }
    .login {
      position: absolute;
      bottom: rem(30px);
      width: rem(224px);
      height: rem(76px);
      margin-top: rem(20px);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .user {
      position: absolute;
      bottom: rem(100px);
      margin-top: rem(20px);
      color: #fff;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .touxiang {
        width: rem(76px);
        height: rem(76px);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .psd{
        width: rem(300px);
        margin-top: rem(20px);
      }
      .title {
        margin-left: rem(10px);
        margin-top: rem(10px);
      }
    }
  }
}
.right {
  width: 100%;
  height: 100%;
}
</style>
