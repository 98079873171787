import axios from 'axios'
// 封装公共请求方法
class ZxRequest {
  constructor(config) {
    // 创建axios实例
    this.instance = axios.create(config)
    // 保存基本信息
    this.interceptors = config.interceptors
    //实例请求拦截
    this.instance.interceptors.request.use(
      this.interceptors.requestInterceptor,
      this.interceptors.requestInterceptorCatch
    )
    //所有请求拦截
    this.instance.interceptors.request.use(
      (config) => {
        return config
      },
      (err) => {
        // console.log('请求失败拦截-所有')
        return err
      }
    )
    //所有响应拦截
    this.instance.interceptors.response.use(
      (res) => {
        // console.log('响应成功拦截-所有')
        return res.data
      },
      (err) => {
        // console.log('响应失败拦截-所有')
        return err
      }
    )
    //实例响应拦截
    this.instance.interceptors.response.use(
      this.interceptors.responseInterceptor,
      this.interceptors.responseInterceptorCatch
    )
  }

  request(config) {
    return new Promise((resolve, reject) => {
      if (config.loading === false) {
        this.showLoading = config.loading
      }
      this.instance
        .request(config)
        .then((res) => {
          this.showLoading = true
          resolve(res)
        })
        .catch((err) => {
          this.showLoading = true
          reject(err)
          return err
        })
    })
  }
  get(config) {
    return this.request({
      ...config,
      method: 'get'
    })
  }
  post(config) {
    return this.request({
      ...config,
      method: 'post'
    })
  }
}

export default ZxRequest
