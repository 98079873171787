/**
 * 用于传输string的tcp Client...
 * @author xinlong 
 * @copyright xinbo kids..
 */

 class TStringTCPClient {
    /**
      * 
      * @param {*string} ip 
      * @param {*int} port 
      * @param {*char} sepStr 指令的分隔符...
      */
    constructor(ip, port, sepStr) {
        this.serverip = ip;
        this.serverport = port;
        this.ConnectStatus = 0;
        this.receiveBuffer = "";
        this.sepStr = sepStr;
        this.SelfTimer = null;  // 本地的一个Timer，用于断线的时候，进行断线重连
        let EventEmitter = require('events').EventEmitter
        this.listenerList = new EventEmitter();
        this.StartToWork();
    }

    destroy(){

    }

     /**
      * run TCP Client...
      */
    StartToWork(){
        this.ConnectStatus = 0;
        
        let net = require("net");
        this.client = new net.Socket;
        this.client.connect(this.serverport, this.serverip, ()=>{
            this.ConnectStatus = 1;
        });

        this.client.on("data",(data)=>{
            // 收到了数据....
            this.ReceiveData(data);
            while (true) {
                var RArr = this.ReadAPackage();
                if (RArr == null) {
                    break;
                }
                this.trigger("data", RArr);
            }
        });

        this.client.on("error",(err)=>{
            if (this.ConnectStatus != 2) {
                if (this.ConnectStatus == 1) {
                    this.trigger("error");
                }
                this.ConnectStatus = 2;
                this.SelfTimer = setInterval(()=>{
                    clearInterval(this.SelfTimer);
                    this.StartToWork(); 
                }, 5000);
            }
        });

        this.client.on("close",()=>{
            if (this.ConnectStatus != 2) {
                if (this.ConnectStatus == 1) {
                    this.trigger("error");
                }
                this.ConnectStatus = 2;
                this.SelfTimer = setInterval(()=>{
                    clearInterval(this.SelfTimer);
                    this.StartToWork(); 
                }, 5000);
            }
        });
    }

    /**
    * 接收到了信息
    * @param {*string} inData 
    */
    ReceiveData(inData){
        this.receiveBuffer = this.receiveBuffer + inData
    }

    /**
    * 获取一个指令...
    */
    ReadAPackage(){
        for (var i=0;i<this.receiveBuffer.length;i++){
            var a = this.receiveBuffer.charAt(i);
            if (a == this.sepStr) {
                var S = this.receiveBuffer.subString(0, i);
                if (i == this.receiveBuffer.length-1) {
                    this.receiveBuffer = "";
                } else {
                    this.receiveBuffer = this.receiveBuffer.slice(i+1);
                }
                return S;
            }
        }
        return null;
    }

    /**
    * 发送数据,传入的是一个Buffer
    * @param {*} inBuf 
    */
    SendData(inBuf){
        if (this.ConnectStatus != 1) {
            return;
        }
        this.client.write(inBuf);
    }

    /**
     * 获取TCP的连接状态
     */
    GetConnectionStatus(){
        return this.ConnectStatus;
    }

     /**
     * 留给外面注册事件用额
     * @param {*String 事件的名称} event 
     * @param {*对应的函数} listener 
     */
    on(event, listener) {
        if(event !== 'error') {
            this.listenerList.addListener(event, listener);
        } else {
            this.listenerList.addListener('internalError', listener);
        }
    }
  
    /**
     * 内部触发一个事件的...
     * @param {* string 事件的名称} event 
     * @param  {...any} data 
     */
    trigger(event, ...data) {
        if(event !== 'error') {
            this.listenerList.emit(event, ...data);
        } else {
            this.listenerList.emit('internalError', ...data);
        }
    }
 }

 module.exports = TStringTCPClient;