import { zxRequest } from '../index'

//美国用户注册
export function register(params) {
  return zxRequest.post({
    url: '/user/register.php',
    data: params
  })
}

//用户登录
export function login(params) {
  return zxRequest.post({
    url: '/user/login.php',
    data: params
  })
}

//获取邮箱验证码
export function emailcode(params) {
  return zxRequest.get({
    url: '/mail/send.php',
    params: params
  })
}

//退出账号
export function logout(params) {
  return zxRequest.post({
    url: '/user/logout.php',
    data: params
  })
}

//完善个人信息
export function information(params) {
  return zxRequest.post({
    url: '/user/personal_information.php',
    data: params
  })
}

//获取商品信息
export function goods(params) {
  return zxRequest.get({
    url: 'pay/product_list.php',
    params: params
  })
}

//获取用户的到期时间
export function getvali(params) {
  return zxRequest.get({
    url: '/user/getVali.php',
    params:params
  })
}

//获取邮箱验证码
export function send_pass(params) {
  return zxRequest.get({
    url: '/mail/send_pass.php',
    params: params
  })
}

//重置密码
export function recover(params) {
  return zxRequest.post({
    url: '/user/recover.php',
    data: params
  })
}