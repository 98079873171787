import { createRouter, createWebHashHistory } from 'vue-router';
import { localCache } from '@/utils/utils';

const routes = [
  {
    path: '/',
    redirect: (to) => {
      return { path: '/home' };
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "login" */ '@/views/home/home.vue')
  },
  // {
  //   path: '/analysis',
  //   name: 'analysis',
  //   component: () => import(/* webpackChunkName: "login" */ '@/views/analysis/analysis.vue')
  // },
  // {
  //   path: '/courses',
  //   name: 'courses',
  //   component: () => import(/* webpackChunkName: "login" */ '@/views/courses/courses.vue')
  // },
  // {
  //   path: '/classroom',
  //   name: 'classroom',
  //   component: () => import(/* webpackChunkName: "login" */ '@/views/classroom/classroom.vue')
  // },
  {
    path: '/buystore',
    name: 'buystore',
    component: () => import(/* webpackChunkName: "login" */ '@/views/buystore/buystore.vue')
  },
  {
    path: '/registered',
    name: 'registered',
    component: () => import(/* webpackChunkName: "login" */ '@/views/registered/registered.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue')
  },
  {
    path: '/play',
    name: 'play',
    component: () => import(/* webpackChunkName: "login" */ '@/views/gogame/index.vue')
  },
  {
    path: '/biglevel',
    name: 'biglevel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/gogame/biglevel.vue')
  },
  {
    path: '/smalllevel',
    name: 'smalllevel',
    component: () => import(/* webpackChunkName: "login" */ '@/views/gogame/smalllevel.vue')
  },
  {
    path: '/chess',
    name: 'chess',
    component: () => import(/* webpackChunkName: "login" */ '@/views/gogame/chess.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "login" */ '@/views/forgot/forgot.vue')
  },
  {
    path: '/kifu',
    name: 'kifu',
    component: () => import(/* webpackChunkName: "login" */ '@/views/kifu/kifu.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "login" */ '@/views/forgot/forgot.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// 路由守卫
// router.beforeEach((to) => {
//   if (to.path !== '/login') {
//     const token = localCache.get('token')
//     if (!token) {
//       return '/login'
//     }
//   }
// })

export default router;
